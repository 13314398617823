<template>
  <!-- 操作日志详情页面 -->
  <div class="positionT0L0">
    <a-form-model
      :model="form"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item label="操作人">
        <span>{{ form.operator }}</span>
      </a-form-model-item>
      <a-form-model-item label="操作描述">
        <span>{{ form.describe }}</span>
      </a-form-model-item>
      <a-form-model-item label="操作模块">
        <span>{{ form.model }}</span>
      </a-form-model-item>
      <a-form-model-item label="创建时间">
        <span>{{ form.createTime }}</span>
      </a-form-model-item>
      <a-form-model-item label="方法名称">
        <span>{{ form.methodName }}</span>
      </a-form-model-item>
      <a-form-model-item label="方法参数">
        <span>{{ form.parameter }}</span>
      </a-form-model-item>
    </a-form-model>
    <!-- 底部 -->
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button @click="() => $router.go(-1)">返回</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { GetOperationInfoApi } from "@/request/api/systemManage";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    GetOperationInfoApi({ id: this.$route.params.id }).then(
      ({ code, data }) => {
        if (code == 200) {
          this.form = data;
          console.log(data);
        }
      }
    );
  },
  data() {
    return {
      form: {
        operator: "",
        describe: "",
        model: "",
        createTime: "",
        methodName: "",
        parameter: "",
      },
    };
  },
};
</script>
 
<style lang="less" scoped>
</style>